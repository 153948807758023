import request from '@/utils/request'

export function getHomeImg() {
  return request({
    url: '/home/getHomeImg',
    method: 'post',
    data: {}
  })
}
export function getDigital() {
  return request({
    url: '/home/digital',
    method: 'post',
    data: {}
  })
}