<template>
    <div class="digiTal">
        <IndexTop></IndexTop>
        <div class="content">
            <template v-for="(item, index) in digiTalData" :key="index">
                <div class="content-list">
                <div class="list-left">
                    <img :src="item.img_url" alt="">
                </div>
                <div class="list-center">
                    <div class="center-top">
                        <span>{{ item.title }}</span>
                        <span></span>
                    </div>
                    <p>{{ item.des }}</p>
                </div>
                <div class="list-right"></div>
            </div>
            <el-collapse v-model="activeName" accordion>
                <el-collapse-item title="查看详情" :name="index">
                    <div class="detail">
                        <embed :src="item.file_url" type="application/pdf" width="100%" height="500px"><embed>
                    </div>
                </el-collapse-item>
            </el-collapse>
            </template>
        </div>
        <IndexFoot></IndexFoot>
    </div>
</template>
<script setup>
import IndexTop from '../components/indexTop.vue'
import IndexFoot from '../components/indexFoot.vue'
import { ref,onMounted } from 'vue'
import { getDigital } from '@/api/home'
const activeName = ref('0')
const digiTalData = ref([])
onMounted(() => {
    getDigital().then(res => {
        digiTalData.value = res.data
    })
})
</script>
<style scoped lang="scss">
.digiTal {
    .content {
        min-height: calc(100vh - 80px);
        display: flex;
        flex-direction: column;
        font-size: 20px;
        width: 70%;
        margin: 0 auto;
        margin-top: 120px;

        .detail {
            display: flex;
            flex-direction: column;

            img {
                width: 100%;
                height: auto;
            }

        }

        .content-list {
            padding-right: 20px;
            box-sizing: border-box;
            margin-top: 20px;
            border-radius: 5px;
            background: white;
            display: flex;
            height: 200px;

            .list-left {
                width: 200px;
                flex-shrink: 0;
                margin-right: 20px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .list-center {
                .center-top {
                    margin-top: 10px;

                    :nth-child(1) {
                        font-size: 22px;
                        font-weight: bold;
                        margin-right: 20px;
                    }

                    :nth-child(2) {
                        font-size: 14px;
                        font-weight: bold;
                    }

                }

                p {
                    height: 120px;
                    font-size: 15px;
                    text-indent: 30px;
                    overflow-y: auto;
                }
            }
        }
    }
}
</style>
